exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-career-index-js": () => import("./../../../src/pages/career/index.js" /* webpackChunkName: "component---src-pages-career-index-js" */),
  "component---src-pages-community-guideline-en-mdx": () => import("./../../../src/pages/community-guideline.en.mdx" /* webpackChunkName: "component---src-pages-community-guideline-en-mdx" */),
  "component---src-pages-community-guideline-zh-mdx": () => import("./../../../src/pages/community-guideline.zh.mdx" /* webpackChunkName: "component---src-pages-community-guideline-zh-mdx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-epayment-tsx": () => import("./../../../src/pages/epayment.tsx" /* webpackChunkName: "component---src-pages-epayment-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-promotions-citi-index-tsx": () => import("./../../../src/pages/promotions/citi/index.tsx" /* webpackChunkName: "component---src-pages-promotions-citi-index-tsx" */),
  "component---src-pages-promotions-citi-tnc-en-md": () => import("./../../../src/pages/promotions/citi/tnc.en.md" /* webpackChunkName: "component---src-pages-promotions-citi-tnc-en-md" */),
  "component---src-pages-promotions-citi-tnc-zh-md": () => import("./../../../src/pages/promotions/citi/tnc.zh.md" /* webpackChunkName: "component---src-pages-promotions-citi-tnc-zh-md" */),
  "component---src-pages-promotions-citiyro-index-tsx": () => import("./../../../src/pages/promotions/citiyro/index.tsx" /* webpackChunkName: "component---src-pages-promotions-citiyro-index-tsx" */),
  "component---src-pages-promotions-citiyro-tnc-en-md": () => import("./../../../src/pages/promotions/citiyro/tnc.en.md" /* webpackChunkName: "component---src-pages-promotions-citiyro-tnc-en-md" */),
  "component---src-pages-promotions-citiyro-tnc-zh-md": () => import("./../../../src/pages/promotions/citiyro/tnc.zh.md" /* webpackChunkName: "component---src-pages-promotions-citiyro-tnc-zh-md" */),
  "component---src-pages-promotions-dbs-index-tsx": () => import("./../../../src/pages/promotions/dbs/index.tsx" /* webpackChunkName: "component---src-pages-promotions-dbs-index-tsx" */),
  "component---src-pages-promotions-dbs-tnc-en-md": () => import("./../../../src/pages/promotions/dbs/tnc.en.md" /* webpackChunkName: "component---src-pages-promotions-dbs-tnc-en-md" */),
  "component---src-pages-promotions-dbs-tnc-zh-md": () => import("./../../../src/pages/promotions/dbs/tnc.zh.md" /* webpackChunkName: "component---src-pages-promotions-dbs-tnc-zh-md" */),
  "component---src-pages-promotions-dbsyro-index-tsx": () => import("./../../../src/pages/promotions/dbsyro/index.tsx" /* webpackChunkName: "component---src-pages-promotions-dbsyro-index-tsx" */),
  "component---src-pages-promotions-dbsyro-tnc-en-md": () => import("./../../../src/pages/promotions/dbsyro/tnc.en.md" /* webpackChunkName: "component---src-pages-promotions-dbsyro-tnc-en-md" */),
  "component---src-pages-promotions-dbsyro-tnc-zh-md": () => import("./../../../src/pages/promotions/dbsyro/tnc.zh.md" /* webpackChunkName: "component---src-pages-promotions-dbsyro-tnc-zh-md" */),
  "component---src-pages-promotions-index-tsx": () => import("./../../../src/pages/promotions/index.tsx" /* webpackChunkName: "component---src-pages-promotions-index-tsx" */),
  "component---src-pages-promotions-mastercard-index-tsx": () => import("./../../../src/pages/promotions/mastercard/index.tsx" /* webpackChunkName: "component---src-pages-promotions-mastercard-index-tsx" */),
  "component---src-pages-promotions-mastercard-tnc-en-md": () => import("./../../../src/pages/promotions/mastercard/tnc.en.md" /* webpackChunkName: "component---src-pages-promotions-mastercard-tnc-en-md" */),
  "component---src-pages-promotions-mastercard-tnc-zh-md": () => import("./../../../src/pages/promotions/mastercard/tnc.zh.md" /* webpackChunkName: "component---src-pages-promotions-mastercard-tnc-zh-md" */),
  "component---src-pages-promotions-mgm-index-tsx": () => import("./../../../src/pages/promotions/mgm/index.tsx" /* webpackChunkName: "component---src-pages-promotions-mgm-index-tsx" */),
  "component---src-pages-promotions-mgm-tnc-en-md": () => import("./../../../src/pages/promotions/mgm/tnc.en.md" /* webpackChunkName: "component---src-pages-promotions-mgm-tnc-en-md" */),
  "component---src-pages-promotions-mgm-tnc-zh-md": () => import("./../../../src/pages/promotions/mgm/tnc.zh.md" /* webpackChunkName: "component---src-pages-promotions-mgm-tnc-zh-md" */),
  "component---src-pages-promotions-mox-index-tsx": () => import("./../../../src/pages/promotions/mox/index.tsx" /* webpackChunkName: "component---src-pages-promotions-mox-index-tsx" */),
  "component---src-pages-promotions-new-25-index-tsx": () => import("./../../../src/pages/promotions/new25/index.tsx" /* webpackChunkName: "component---src-pages-promotions-new-25-index-tsx" */),
  "component---src-pages-promotions-new-25-tnc-en-md": () => import("./../../../src/pages/promotions/new25/tnc.en.md" /* webpackChunkName: "component---src-pages-promotions-new-25-tnc-en-md" */),
  "component---src-pages-promotions-new-25-tnc-zh-md": () => import("./../../../src/pages/promotions/new25/tnc.zh.md" /* webpackChunkName: "component---src-pages-promotions-new-25-tnc-zh-md" */),
  "component---src-pages-street-hail-tsx": () => import("./../../../src/pages/street-hail.tsx" /* webpackChunkName: "component---src-pages-street-hail-tsx" */),
  "component---src-pages-taxi-dollars-gift-tsx": () => import("./../../../src/pages/taxi-dollars-gift.tsx" /* webpackChunkName: "component---src-pages-taxi-dollars-gift-tsx" */),
  "component---src-pages-taxi-dollars-tnc-en-md": () => import("./../../../src/pages/taxi-dollars-tnc.en.md" /* webpackChunkName: "component---src-pages-taxi-dollars-tnc-en-md" */),
  "component---src-pages-taxi-dollars-tnc-zh-md": () => import("./../../../src/pages/taxi-dollars-tnc.zh.md" /* webpackChunkName: "component---src-pages-taxi-dollars-tnc-zh-md" */),
  "component---src-pages-taxi-dollars-tsx": () => import("./../../../src/pages/taxi-dollars.tsx" /* webpackChunkName: "component---src-pages-taxi-dollars-tsx" */)
}

