import * as React from 'react';
import clsx from 'clsx';
import { styled } from '@mui/system';
import useSwitch, { UseSwitchParameters } from '@mui/base/useSwitch';

export default function Switch({textLeft="Left", textRight="Right", ...props}: {textLeft:string, textRight:string} & UseSwitchParameters) {
  const { getInputProps, checked, disabled, focusVisible } = useSwitch(props);

  const stateClasses = {
    checked,
    disabled,
    focusVisible,
  };

  return (
    <SwitchRoot className={clsx(stateClasses)}>
      <SwitchTrack>
        <Label>{textLeft}</Label>
        <Label>{textRight}</Label>
        <SwitchThumb className={clsx(stateClasses)} sx={{backgroundColor: 'primary.main'}}/>
      </SwitchTrack>
      <SwitchInput {...getInputProps()} aria-label="switch" />
    </SwitchRoot>
  );
}

const SwitchRoot = styled('span')`
  display: inline-block;
  position: relative;
  padding: 0px;
`;

const SwitchInput = styled('input')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
  cursor: pointer;
`;

const SwitchThumb = styled('span')(
({ theme }) => `
  position: absolute;
  display: block;
  width: 50%;
  height: 100%;
  border-radius: 15px;
  top: 0px;
  left: 0px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
  }

  &.focusVisible {
    background-color: #79b;
  }

  &.checked {
    transform: translateX(100%);

    &::before {
  
    }
  }
`);

const SwitchTrack = styled('span')(
  ({ theme }) => `
  background-color: ${theme.palette.mode === 'dark' ? '#CCC' : '#CCC'};
  border-radius: 15px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
`);

const Label = styled('label')`
  color: white;
  text-align: center;
  z-index: 2;
  pointer-events: none;
  padding: 0 15px;
  font-size: 0.9rem;
`;
