import React from 'react';

export type Crumb = {
  crumbLabel:string,
  pathname:string,
}

export type PageContextType = {
  lang: 'zh' | 'en',
  layout?: 'home' | 'partnership',
  originalPath?: string,
  breadcrumb?: { crumbs:Crumb[] }
};

const PageContext = React.createContext<PageContextType>({lang: 'zh'});

export const PageContextProvider = ({ pageContext, children }) => {
  return <PageContext.Provider value={pageContext}>{children}</PageContext.Provider>;
};

export const usePageContext = () => React.useContext<PageContextType>(PageContext);
