import React, { ReactNode } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import theme from '../styles/theme';
import Navbar from './header/Navbar';
import Footer from './Footer';
import { SEO } from './SEO';

import '../styles/global.css';
import { PageContextType } from '../i18n/page-context';

const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  flex: 1 0;
  margin-bottom: -20px;
  z-index: 1;
  position: relative;
`;


const Layout = ({ children, pageContext }:{children:ReactNode, pageContext:PageContextType}) => {
  const { t } = useTranslation('footer');

  if (pageContext.layout === 'home') {
    return (
      <div style={{height: '100%'}}>
        <SEO />
        <ThemeProvider theme={theme}>
          {children}
        </ThemeProvider>
      </div>
    );
  }

  // Custom header
  let header = <Navbar />;

  if (pageContext.layout === 'partnership') {
    header = (<Navbar layout="partnership"/>);
  } else if (pageContext.layout === 'home') {
    header = <></>;
  }

  // Custom footer
  let footer = <Footer />;

  if (pageContext.layout) {
    footer = (
      <Footer layout={pageContext.layout}/>
    );
  }

  return (
    <div style={{height: '100%'}}>
      <SEO />
      <ThemeProvider theme={theme}>
        <Wrapper>
          {header}
          <Main>{children}</Main>
          {footer}
        </Wrapper>
      </ThemeProvider>
    </div>
  );
};

export default Layout;
