import React from 'react';
import { useTranslation } from 'react-i18next';

import { NavBarLink } from './navbar-styles';

const NavbarLinks = () => {
  const { t } = useTranslation();

  return (
    <>
      <NavBarLink to='/partnership/ads'>{t('navbar.ads')}</NavBarLink>
      <NavBarLink to='/partnership/partner'>{t('navbar.partner')}</NavBarLink>
    </>
  );
};

export default NavbarLinks;
