import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';

import { localizedPath } from './helpers';
import { usePageContext } from './page-context';

export const LocalizedLink = ({ to, language, children, ...props } : {
  to:string, 
  language?: 'en' | 'zh', 
  children:React.ReactNode
}) => {
  const { lang } = usePageContext();
  const path = localizedPath({
    defaultLang: 'zh',
    prefixDefault: false,
    locale: language || lang,
    path: to,
  });

  return (
    <GatsbyLink
      {...props}
      to={path}
    >
      {children}
    </GatsbyLink>
  );
};
