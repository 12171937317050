
function localizedPath({ defaultLang, prefixDefault, locale, path }) {
  if (!path) {
    return null;
  }

  // The default language isn't prefixed
  if (locale === defaultLang && !prefixDefault) {
    return path;
  }

  const [, base] = path.split('/');

  // If for whatever reason we receive an already localized path
  // (e.g. if the path was made with location.pathname)
  // just return it as-is.
  if (base === locale) {
    return path;
  }

  // No need for relative path
  if (!path.startsWith('/')) {
    return path;
  }

  // If it's another language, prefix with the locale
  return `/${locale}${path}`;
}

module.exports = {
  localizedPath,
};
