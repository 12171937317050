import React from 'react';
import Switch from '../Switch';
import { usePageContext  } from '../../i18n/page-context';
import { localizedPath } from '../../i18n/helpers';

const LanguageMenu = () => {
  const { originalPath, lang } = usePageContext();

  const toggleLang = () => {
    location.href = localizedPath({
      defaultLang: 'zh',
      prefixDefault: false,
      locale: (lang === 'en' ? 'zh' : 'en'),
      path: originalPath,
    });
  };

  return (
    <Switch checked={lang === 'en'}
      textLeft="中文" textRight="ENG"
      onChange={() => toggleLang()} />
  );

};

export default LanguageMenu;
