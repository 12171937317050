import React from 'react';
import { useTranslation } from 'react-i18next';

import { NavBarLink } from './navbar-styles';

const NavbarLinks = () => {
  const { t } = useTranslation();

  return (
    <>
      <NavBarLink to='/promotions'>{t('navbar.latest_promotions')}</NavBarLink>
      <NavBarLink to='/epayment'>{t('navbar.epayment_services')}</NavBarLink>
      <NavBarLink to='/street-hail'>{t('navbar.street_hail')}</NavBarLink>
      <NavBarLink to='/taxi-dollars'>Taxi Dollars</NavBarLink>
      <NavBarLink to='/contact'>{t('navbar.contact')}</NavBarLink>
    </>
  );

};

export default NavbarLinks;
