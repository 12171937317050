import React from 'react';
import { Helmet } from 'react-helmet';

import { useSiteMetadata } from '../hooks/use-site-metadata';
import { usePageContext } from '../i18n/page-context';

const RECAPTCHA_SITE_KEY = `${process.env.GATSBY_RECAPTCHA_SITE_KEY}`;

export const SEO = ({ 
  title, 
  description, 
  pathname, 
  children, 
  titlePrefix, 
  isArticle,
} : { 
  title?:string, 
  description?:string, 
  pathname?:string, 
  children?:React.ReactNode, 
  titlePrefix?:string, 
  isArticle?:boolean,
}) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
  } = useSiteMetadata();

  const { lang } = usePageContext();

  const seo = {
    title: titlePrefix ? (titlePrefix + ' | ' + defaultTitle) : (title || defaultTitle),
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ''}`,
  };

  return (
    <Helmet>
      <html lang={lang} />

      <title>{seo.title}</title>

      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      <link id="icon" rel="icon" href={seo.image} type="image/x-icon"/>

      {/* Open Graph */}
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:locale" content={lang} />
      {isArticle && <meta property="og:type" content="article" />}

      {children}
    </Helmet>
  );
};
