module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/Layout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"exclude":["**/404/**"],"crumbLabelUpdates":[{"pathname":"/partnership/partner/press/pcm","crumbLabel":"PCMarket"},{"pathname":"/partnership/partner/press/am730","crumbLabel":"am730"},{"pathname":"/partnership/partner/press/unwire","crumbLabel":"Unwire"},{"pathname":"/partnership/partner/press/ezone","crumbLabel":"E-zone"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
